import React, { forwardRef, useRef } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import NextPage from '../../components/navigation/next/next-container';
import PreviousPage from '../../components/navigation/previous/previous-container';
import SEO from '../../components/seo/seo';

import useVisibleScrollBar from '../../utils/use-visible-scrollbar';

import './page-11.css';

const PageEleven = forwardRef((props, ref) => (
  <>
    <SEO title="Page 11" />
    <main className="page11">
      <div className="page__image-container page11__image-portrait-container">
        <StaticImage
          alt="Sockpicker people, including a baby, triplets and one hanging upside down"
          aspectRatio={3500 / 4003}
          className="page11__image-portrait page__image-border"
          height={750}
          layout="constrained"
          loading="eager"
          objectFit="contain"
          placeholder="tracedSVG"
          src="../../images/page-11-portrait.png"
        />
      </div>
      <div className="page__image-container page11__image-landscape1-container">
        <StaticImage
          alt="A sockpicker with a baby and one hanging upside down"
          aspectRatio={3500 / 4003}
          className="page11__image-landscape1 page__image-border"
          height={750}
          layout="constrained"
          loading="eager"
          placeholder="tracedSVG"
          src="../../images/page-11-landscape1.png"
        />
      </div>
      <div
        className="page__text-container"
        ref={ref}
      >
        <div className="page__text page11__right">
          <div>
            <p>
              Amanda followed Jimbug&apos;s instructions.
            </p>
            <p>
              And before her was the most amazing sight she had ever seen...
            </p>
            <p>
              She was staring down into a big room full of little creatures who looked
              {' '}
              <strong>just like Jimbugs!</strong>
            </p>
          </div>
          <div className="page__image-container page11__image-landscape2-container">
            <div className="page__image-border-container">
              <StaticImage
                alt="Sockpicker triplets"
                aspectRatio={3500 / 4017}
                height={300}
                layout="constrained"
                loading="eager"
                objectFit="contain"
                placeholder="tracedSVG"
                src="../../images/page-11-landscape2.png"
              />
            </div>
          </div>
        </div>
      </div>
      <PreviousPage to="/page-10" />
      <NextPage to="/page-12" />
    </main>
  </>
));

const PageElevenContainer = () => {
  const textRef = useRef();
  useVisibleScrollBar(textRef, 'page__text-container-scroll-indicator');

  return <PageEleven ref={textRef} />;
};

export default PageElevenContainer;
